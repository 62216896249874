.container {
  padding: 0px 16px 0px 16px;
  
}

.options {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  height: 45px;
  border-left: var(--meme-comment-count-border);
  border-right: var(--meme-comment-count-border);
}
.optionsLeft {
  display: flex;
  height: 100%;
}

.commentBtn {
  display: flex !important;
  align-items: center !important;

}

.commentBtn > p {
  font-family: var(--main-font) !important;
  margin-left: 5px;
  margin-right: 20px;
  color: var(--meme-comment-counter);
}


@media (max-width: 699px) {
  .container {
    padding: 0;
  }
  .commentBtn {
    border-left: none;
  }

  .commentBtn > p {
    margin: 0 10px 0 0;
    color: #fff;
  }
}