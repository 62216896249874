.container .containerMeme {
  border: var(--meme-comment-border);
  border-radius: 0 0 15px 15px;
  margin: 0 16px 10px 16px;
  
}

.containerMeme {
  border: var(--meme-comment-border);
  border-radius: 0 0 15px 15px;
  margin: 0 16px 10px 16px;
}

@media (max-width: 699px) {
  .containerMeme {
    border: var(--meme-comment-border);
    border-radius: 15px;
    margin: 10px 0 0 0;
  }

  .container {
    border: none;
    border-radius: 15px;
    margin: 0;
  }
}
