.container {
  padding: 0 16px 0px 16px;
}

.optionsDashboard {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  height: 45px;
  border-left: var(--meme-comment-count-border);
  border-right: var(--meme-comment-count-border);
}

.options {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  height: 45px;
}

.optionsLeft {
  display: flex;
  height: 100%;
}
.optionsRight {
  display: flex !important;
  height: 100%;
}

.commentBtn {
  display: flex !important;
  align-items: center !important;
}

.commentBtn > p {
  font-family: var(--main-font) !important;
  margin-left: 5px;
  margin-right: 20px;
  color: var(--meme-comment-counter);
}

.likesOptions {
  background-color: var(--main-color);
  display: flex;
  align-items: center;
  border-radius: 5px 5px 5px 5px;
  padding: 5px;
  margin-right: 15px;
}

.likesOptions > p {
  font-family: var(--main-font) !important;
  color: white;
  margin-left: 5px;
  margin-right: 15px;
}

.likesBtns {
  margin-left: 5px !important;
  padding: 5px !important;
}

.likesBtns:hover {
  background-color: #ff5e74 !important;
}

.likesText {
  color: #fff !important;
}

.likesTextDark {
  color: #000 !important;
}

.rightBtns {
  border-radius: 5px !important;
  border: var(--meme-option-border) !important;
  align-items: center !important;
  min-width: unset !important;
  margin-right: 15px !important;
}

.rightBtns:hover {
  background-color: var(--meme-option-hover) !important;
  border-color: var(--meme-option-hover) !important;
}

.rightBtnsSelected {
  border-radius: 5px 5px 5px 5px !important;
  border: 1px solid var(--main-selected) !important;
  align-items: center !important;
  min-width: unset !important;
  margin-right: 15px !important;
  background-color: var(--meme-option-selected) !important;
  border-color: var(--meme-option-selected) !important;
}

.follow {
  display: flex;
  justify-content: center;
  align-items: center;
}

.followBtn {
  border-radius: 20px 20px 20px 20px !important;
  border: var(--meme-option-border) !important;
  align-items: center !important;
  min-width: unset !important;
  padding: 10px 15px 10px 15px !important;
  height: 25px;
  align-self: center;
}

.followText {
  color: var(--meme-option-border) !important;
  font-family: var(--main-font) !important;
  font-weight: bold !important;
}

.followBton:hover {
  background-color: var(--meme-option-hover) !important;
  border-color: var(--meme-option-hover) !important;
}

@media (max-width: 699px) {
  .container {
    margin-top: 10px;
    padding: 0;
  }
  .commentBtn {
    border-left: none;
  }

  .commentBtn > p {
    margin: 0 10px 0 0;
    color: #fff;
  }

  .optionsLeft {
    display: flex;
    height: 100%;
    background-color: var(--main-color);
    border-radius: 10px;
  }

  .likesOptions {
    border-radius: 5px;
  }

  .divider {
    width: 0.5px;
    height: 100%;
    background-color: rgb(196, 196, 196);
  }
  .optionsRight {
    margin-right: 0;
  }
  .likesBtns {
    margin-left: 10px !important;
    padding: 5px !important;
  }

  .rightBtnsSelected {
    background-color: var(--nav-selected) !important;
  }
}
