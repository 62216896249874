@import "constants.css";

a{
  color:red !important;
}

h1{
  font-size: larger;
  font-weight: bolder !important;
}

input{
  font-weight: bolder !important;
  height:4px !important;
}

.container {
  height: 100%;
  width: 100%;
  display: flex;
}

.navbar {
  height: 100%;
  width: 15%;
}

.dashboard {
  height: 100%;
  background: var(--theme-page-background);
  width: 100%;
  overflow: scroll;
}

.dashboard::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.dashboard::-webkit-scrollbar {
  display: none;
}

.top_bar {
  height: 8%;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  background-color: var(--theme-page-background);
}

.rightPart {
  display: flex;
  background-color: var(--theme-page-background);
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow: hidden;
}

.center {
  text-align: center;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-primary {
  background-color: var(--main-color) !important;
  color: #fff !important;
  border-radius: 50%;
}
.btn-primary:hover {
  color: #000 !important;
}

.btn-black {
  background-color: #000000 !important;
  color: #fff !important;
  border-radius: 50%;
}

.btn-white {
  width: 150px;
  background-color: #fff !important;
  color: #000 !important;
  border-radius: 50%;
}
.btn-white:hover {
  background-color: #fff !important;
  color: #f00 !important;
}

.btn-red {
  width: 150px;
  background-color: rgba(255, 0, 0, 0) !important;
  color: #ffffffc7 !important;
  border-radius: 50%;
  border-color: #ffffffc7 !important;
  border-width: thin !important;
}
.btn-red:hover {
  background-color: #f00 !important;
  color: #000 !important;
}

.main-content {
  max-height: 100vh;
  max-width: 99vh;
}

.saves {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 100px;
  grid-gap: 10px;
}

.comment {
  max-height: 100px;
  display: flex !important;
  justify-content: flex-start;
  align-items: center;
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.comment .content {
  margin-left: 1rem !important;
}

.comment .username {
  color: #909ba7;
}

.dashboard-scroll,
.myMemes {
  height: 100%; /* 73% */
  max-width: 100%;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.dashboard-scroll::-webkit-scrollbar,
.myMemes::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.circle {
  width: 400px;
  height: 400px;
  border-radius: 400px;
  background-color: red;
  text-align: center;
  display: table-cell;
  overflow: hidden;
  vertical-align:middle;
  margin-top: 130px !important;
}

.avisoMessageA {
  font-family: var(--main-font) !important;
  font-size: 30px !important;
  font-weight: bolder !important;
  text-align: center !important;
}

.avisoMessageB {
  font-family: var(--main-font) !important;
  font-size: 22px !important;
  font-weight: bold !important;
  text-align: center !important;
  color: white;
}

.avisoMessageC {
  font-family: "IBMPlexSans-Light", sans-serif !important;
  font-size: 12px !important;
  text-align: center !important;
}

.buttonContainer {
  position: relative !important;
}

.buttonCenter {
  position: absolute !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

.avisoButton {
  font-family: var(--main-font) !important;
  font-size: var(--strong-text-font-size) !important;
  border-radius: 50px !important;
  text-transform: unset !important;
  border: none !important;
  background-color: white !important;
  width: 130px !important;
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.048), 0 11px 11px rgba(0, 0, 0, 0.072),
    0 15px 15px rgba(0, 0, 0, 0.086) !important
;
}

.avisoButton:hover {
  background-color: white !important;
}

@media (max-width: 700px) {
  .navbar {
    display: none;
  }

  .dashboard-scroll,
  .myMemes {
    height: 100%; /* 56% */
  }
  .dashboard {
    height: 100%;
    width: 100%;
    background-color: var(--theme-page-background);
    overflow: scroll;
  }
}
