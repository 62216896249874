.container {
  display: flex;
  max-width: 100%;
  margin: 10px;
  justify-content: center;
  align-items: center;
}

.actionsContainer {
  justify-content: space-evenly;
  align-items: center;
  display: flex;
  width: 45%;
  padding: 10px;
  margin-right: 5px;
}

.messageContainer {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: var(--commenter-theme-message);
  border-radius: 7px;
}

.messageContainer > div {
  text-align: center;
}

.message {
  font-family: "IBMPlexSans", sans-serif !important;
  font-weight: bold;
  letter-spacing: 0.2px;
  font-weight: 200 !important;
  text-transform: unset !important;
  color: var(--theme-page-text) !important;
  width: 55%;
}

.actionButtons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.login {
  font-family: var(--main-font) !important;
  border-radius: 15px !important;
  text-transform: unset !important;
  color: var(--main-color) !important;
  background-color: var(--theme-page-background) !important;
  border: var(--login-button-border) !important;
  width: 110px;
  height: 30px !important;
}

.login:hover {
  background-color: var(--nav-hover) !important;
  border: 1px solid var(--nav-hover) !important;
  color: var(--login-button-text-hover) !important;
}

.signin {
  font-family: var(--main-font) !important;
  border-radius: 15px !important;
  text-transform: unset !important;
  color: white !important;
  background-color: var(--main-color) !important;
  width: 110px;
  height: 30px !important;
  border: 1px solid var(--main-color) !important;
}

.signin:hover {
  background-color: var(--main-color) !important;
  color: black !important;
}

.picture {
  width: 7%;
  padding: 0 0 0 12px;
}

.textfield {
  width: 78%;
}
.textfieldMeme {
  width: 80%;
  padding: 2px 0px 2px 20px;
  border: var(--meme-comment-border);
  margin-right: 1px;
  border-radius: 15px;
  background-color: var(--theme-page-background);
}

.button {
  width: 15%;
}

.buttonMeme {
  width: 15%;
  text-align: center;
}
.commentInputMeme textarea {
  color: var(--commenter-text) !important;
  border-bottom: none !important;
}

.commentInputMeme > div::before{
  border-bottom: none !important;
}

/* border bottom over textarea on focus  */
.commentInputMeme > div::after {
  border-bottom: none !important;
}

.commentInput textarea {
  color: var(--commenter-text) !important;
}

/* border bottom over textarea on focus  */
.commentInput > div::after {
  border-bottom: 2px solid #000;
}

.commentButtonMeme {
  font-family: var(--main-font) !important;
  text-transform: none !important;
  color: var(--main-color) !important;
  outline: 1px solid var(--main-color) !important;
  width: 80px;
  height: 25px;
  border-radius: 15px !important;
}

.commentButton {
  font-family: var(--main-font) !important;
  text-transform: none !important;
  color: #fff !important;
  background-color: var(--main-color) !important;
  margin-bottom: 5px !important;
  margin-left: 5px !important;
}



@media (max-width: 699px) {
  .picture {
    width: 10%;
  }

  .textfield {
    width: 60%;
  }

  .button {
    width: 30%;
  }

  
  .commentInput {
    width: 80% !important;
  }

  .container{
    padding: 0px;
    display: flex;
    align-items: center;
    margin: 0px 10px 10px 10px;
    justify-content: center;
  }

  .actionsContainer {
    width: 100%;
    display: flex;
    padding-left: 15px;
    padding-right: 15px;
    justify-content: space-evenly;
    align-items: center;
  }

  .messageContainer {
    flex-direction: column;
    width: 100%;
  }

  .message {
    width: 100%;
  }

  .textfieldMeme {
    width: 70%;
    border-radius: 15px 0px 0px 15px;
    height: 30px !important;
    
  }

  
  .commentButtonMeme {
    width: 30% !important;
    border-radius: 0px 15px 15px 0px !important;
    height: 34px !important;
    align-self: center;
  }
}