.imgPreview {
  max-width: 80vw;
  max-height: 80vh;
  height: 30vh;
}

.dialogContent{
  height: 100%;
}

.image {
  width: 100%;
  height: 100%;
}

.imageAdd {
  height: 5vh !important;
}

.addMeme {
  display: flex;
  justify-content: center;
  margin-top: 2rem !important;
}

.container {
  margin-top: 1rem;
}