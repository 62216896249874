.container {
  max-width: 100%;
}
.descriptionMeme {
  color: var(--meme-content-description);
  font-family: "IBMPlexSans-Light", sans-serif !important;
  font-size: 13px;
  word-wrap: break-word !important;
  padding: 10px 16px 10px 16px;
  margin-left: 10px !important;
}

.descriptionDashboard {
  color: var(--meme-content-description);
  font-family: "IBMPlexSans-Light", sans-serif !important;
  font-size: 13px;
  word-wrap: break-word !important;
  padding: 0 16px 0 16px;
}


.description > p {
  margin: 2px 10px 5px 0;
}

.image {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  display: flex;
  object-position: 50% 50%;
  padding: 0 16px 0 16px;
}

.image > img {
  width: 100%;
}

@media (max-width: 699px) {
  .descriptionMeme {
    margin: 0;
    padding: 0;
    height: 10%;
  }

  .descriptionDashboard {
    margin-left: 10px;
    padding: 0;
    height: 10%;
  }
  
  .image {
    padding: 0;
    height: 88%;
  }
}
