.topbar {
  max-width: 80%;
  min-width: 30%;
  padding: 10px 0 0 0;
  justify-content: center;
}

.menuIcon {
  color: var(--main-color) !important;
}

.logo {
  display: flex;
  height: 30px;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.logo img {
  width: 100%;
  height: 100%;
}

@media (max-width: 699px) {
  .mobileContainer {
    display: flex;
    width: 100%;
    height: 7%;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    -webkit-box-shadow: 0 10px 6px -6px #cdcaca;
    -moz-box-shadow: 0 10px 6px -6px #cdcaca;
    box-shadow: 0 10px 6px -6px #cdcaca;
  }

  .userOptions {
    display: flex;
    box-sizing: border-box;
    padding: 10px 10px 10px 15px;
  }
  .logoContainer {
    width: 100%;
    height: 100%;
    background: #fff;
  }

  .mobileLogo {
    display: flex;
    max-height: 100%;
    max-width: 100%;
    align-items: center;
    justify-content: center;

    margin: 15px 50px 10px 20px;
  }
  .mobileLogo > img {
    height: 100%;
    width: 100%;
  }
}
