.infoContainer {
  padding-right: 16px !important;
  padding-left: 16px !important;
}

.info {
  width: 100%;
  height: 50px;
  display: flex !important;
  align-items: center;
  justify-content: space-between;
}

.memeHeader {
  height: fit-content !important;
  color: white !important;
  display: flex !important;
  width: 100%;
}

.memeHeaderDashboard {
  height: fit-content !important;
  color: white !important;
  display: flex !important;
  width: 100%;
}

.profilePicture {
  margin-left: 10px;
  justify-content: center !important;
  align-items: center !important;
  display: flex;
}

.memeInfo {
  text-align: left !important;
  justify-content: center !important;
  align-items: center !important;
  display: flex;
}

.username {
  margin-left: 5px;
  color: var(--main-color);
  font-family: var(--main-font);
}

.category {
  text-transform: uppercase;
  color: gray;
}

.date {
  color: gray;
}

.TopOptions {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.follow {
  display: flex;
  justify-content: center;
  align-items: center;
}

.followBtn {
  font-family: var(--main-font) !important;
  text-transform: unset !important;
  color: var(--main-color) !important;
  border: 1px solid var(--main-color) !important;
  border-radius: 50px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
  line-height: unset !important;
}

.followBtn:hover {
  background-color: var(--followed-button-hover) !important;
  border-color: var(--followed-button-hover) !important;
}

.followBtnSelected {
  font-family: var(--main-font) !important;
  text-transform: unset !important;
  color: var(--main-color) !important;
  border: var(--followed-button-border) !important;
  border-radius: 50px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
  background-color: var(--followed-button-selected) !important;
  line-height: unset !important;
}

.menu {
  margin-left: 3rem;
}

.menu .paper {
  border-radius: 0 7px 7px 7px !important;
  width: fit-content !important;
}

.menu .paper > ul {
  padding: 0 !important;
}

.menu .paper > ul > li {
  font-family: var(--main-font) !important;
  background-color: #fff;
  padding: 0 !important;
}

.menuDark {
  margin-left: 3rem;
}

.menuDark .paper {
  border-top: 1px solid #808080 !important;
  border-left: 1px solid #808080 !important;
  border-right: 1px solid #808080 !important;
  border-radius: 0 7px 7px 7px !important;
  width: fit-content !important;
  background-color: #000;
}

.menuDark .paper > ul {
  padding: 0 !important;
}

.menuDark .paper > ul > li {
  font-family: var(--main-font) !important;
  background-color: #000;
  padding: 0 !important;
  border-bottom: 1px solid #808080 !important;
}

.menuBtn {
  font-family: var(--main-font) !important;
  text-transform: unset !important;
  width: 100% !important;
  height: 100% !important;
  padding-left: 15px !important;
  padding-right: 35px !important;
  border-radius: 0px !important;
}

.menuBtn:hover {
  background-color: var(--main-hover) !important;
}

.menuBtnSelected {
  font-family: var(--main-font) !important;
  text-transform: unset !important;
  width: 100% !important;
  height: 100% !important;
  padding-left: 15px !important;
  padding-right: 35px !important;
  border-radius: 0px !important;
  background-color: var(--main-selected) !important;
}

.menuBtnDark {
  font-family: var(--main-font) !important;
  text-transform: unset !important;
  width: 100% !important;
  height: 100% !important;
  padding-left: 15px !important;
  padding-right: 35px !important;
  border-radius: 0px !important;
  color: #fff !important;
  margin: 0 !important;
}

.menuBtnDark:hover {
  background-color: #393939 !important;
}

.menuBtnDarkSelected {
  font-family: var(--main-font) !important;
  text-transform: unset !important;
  width: 100% !important;
  height: 100% !important;
  padding-left: 15px !important;
  padding-right: 35px !important;
  border-radius: 0px !important;
  background-color: var(--main-color) !important;
  color: #fff !important;
}

@media (max-width: 699px) {
  .infoContainer {
    padding: 0 !important;
    margin: 0;
  }

  .info {
    max-height: 100%;
  }

  .memeHeaderDashboard {
    margin-left: 10px !important;
  }
}
