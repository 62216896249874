.filtersContainer {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  max-width: 800px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  height: fit-content !important;
}

.filtersButton {
  font-family: var(--main-font) !important;
  font-size: 13px !important;
  border-radius: 50px !important;
  text-transform: unset !important;
  border: var(--main-border) !important;
  width: fit-content !important;
  height: fit-content !important;
  margin: 10px 5px 0 5px !important;
  padding: 5px 20px 5px 20px !important;
  color: gray !important;
}

.filtersButton:hover {
  background-color: var(--filter-hover) !important;
  border: 1px solid var(--filter-hover) !important;
}

.filtersButtonSelected {
  font-family: var(--main-font) !important;
  font-size: 13px !important;
  border-radius: 50px !important;
  text-transform: unset !important;
  border: 1px solid #909090 !important;
  width: fit-content !important;
  height: fit-content !important;
  margin: 10px 5px 0 5px !important;
  padding: 5px 20px 5px 20px !important;
  color: var(--filter-selected-text) !important;
  background-color: #909090 !important;
}

@media (max-width: 699px) {
  .mobileContainer {
    max-width: 100%;
    margin: 10px 15px 10px 15px;
    box-sizing: border-box;
    height: 13%;
  }
  .filtersButton {
    margin: 8px 5px 0 5px !important;
    padding: 0 8px 0 8px !important;
  }

  .filtersButtonSelected {
    margin: 8px 5px 0 5px !important;
    padding: 0 8px 0 8px !important;
  }
}
