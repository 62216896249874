.container {
  padding-right: 16px !important;
  padding-left: 16px !important;
}

.profilePicture {
  padding-right: 5px !important ;
  padding-left: 5px !important;
  padding-top: 5px !important;
}

.commentInfo {
  display: flex;
  padding-top: 10px !important;
}

.username {
  color: var(--main-color);
  font-family: var(--main-font);
}

.date {
  font-family: "IBMPlexSans-Light", sans-serif !important;
  color: gray;
  margin-left: 5px;
}

.comment {
  margin-left: 5px;
  color: var(--comment-text);
  font-family: "IBMPlexSans-Light", sans-serif !important;
  font-size: 13px;
  max-width: inherit;
  word-wrap: break-word !important;
}

.commentOptions {
  display: flex;
  align-items: center !important;
  padding-left: 38px !important;
}

.reply {
  font-family: "IBMPlexSans-Light", sans-serif !important;
  color: gray;
}

.reply:hover {
  text-decoration: underline;
  cursor: pointer;
}

.likesOptions {
  display: flex !important;
  align-items: center !important;
}

.likesCount {
  margin: unset !important;
  padding: unset !important;
  color: gray;
}

.menu {
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.viewRepliesContainer {
  display: flex;
  align-items: center !important;
  padding-left: 38px !important;
}

.viewReplies {
  font-family: var(--main-font);
  color: var(--main-color);
}

.viewReplies:hover {
  text-decoration: underline;
  cursor: pointer;
}
