.container {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: white;
  border-radius: 5%;
}

.container .box {
  width: 100%;
  height: 100%;
  position: relative;
  cursor: pointer;
}

.memeuserinfo {
  display: flex;
  align-items: center;
  margin-left: 20px;
  height: 20%;
}

.myMemes {
  z-index: 1;
  position: absolute !important;
  right: 0;
  top: 0;
  background-color: #FFF;
  height: 100vh;
  padding-top: 1rem;
}

.hide {
  display: none !important;
}

.topbar {
  margin: 5px;
  height: 7vh;
}

.pageTitle {
  width: 100%;
  margin-left: 3rem;
}

.saves {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem,1fr));
  grid-gap: 2rem;
}

h1 {
  margin-top: 30px;
  margin-bottom: 30px;
}

@media (max-width: 700px) {
  .saves {
    display: grid;
    justify-content: center;
    overflow-y: scroll;
  }
  h1 {
    margin-top: 0px;
    margin-bottom: 30px;
  }
}