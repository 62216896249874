.contentRightBar {
  height: 100%;
  width: 170px;
  justify-content: space-between;
  background-color: var(--theme-page-background);
  border-left: var(--theme-page-border);
  border-top: var(--theme-page-border);
}

.myMemes {
  height: 95%;
  max-width: 100%;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.myMemes::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.UserOptions {
  height: 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
