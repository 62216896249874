.container {
  width: 100%;
  height: 40%;
  padding: 1rem 5px 1rem 5px;
  border-radius: 3%;
  margin-top: 2em !important;
  max-width: 800px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  border: var(--meme-border) !important;
  background: var(--meme-background) !important;
  
}

.rightPartContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}

.contentCenter {
  
  border-top: var(--meme-comment-border);
}

.contentRight {
  display: flex;
  justify-content: center;
}

.advertisements {
  color: var(--theme-page-text);
}

.content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.contentCenter {
  height: 100%;
  width: 100%;
  max-width: 75%;
}

.contentRight {
  width: 100%;
  max-width: 25%;
}

.dashboard {
  width: 100%;
  height: 35vw;
}

.top_bar {
  height: 5%;
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: var(--theme-page-background);
}

.userMemes .content {
  width: 100%;
  height: 35vw;
  
}

.meme_container {
  width: 100%;
  background: var(--theme-page-background);
  padding: 1rem 5px 1rem 5px;
  margin-top: 1rem !important;
}

.dashboard-scroll {
  height: 100%; /* 73% */
  max-width: 100%;
  overflow: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.dashboard-scroll::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@media (max-width: 699px) {
  .mobileContainerDashboard {
    margin: 20px 15px 0 15px;
    padding: 5px 0 0 0;
    max-width: 100%;
    box-sizing: border-box;
    border-radius: 10px;
    background: var(--meme-background);
  }

  .mobileContainerMeme {
    margin: 20px 15px 0 15px;
    border: 5px;
    border-top: 1px solid var(--meme-border);
    padding: 5px 0 0 0;
    max-width: 100%;
    box-sizing: border-box;
    border-radius: 10px;
    background: var(--theme-page-background);
    
  }
}
