:root {
  --logo-height: 70px;
}
.logo {
  height: var(--logo-height);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo img {
  width: 80%;
}

.navContainer {
  height: calc(100% - var(--logo-height));
  border-right: var(--theme-page-border) !important;
  background-color: var(--theme-page-background) !important;
}

.categoriesText {
  font-family: var(--main-font) !important;
  width: 100%;
  font-size: var(--strong-text-font-size) !important;
  color: var(--theme-page-text);
}

.categoriesDropdown {
  box-sizing: content-box !important;
  border-radius: 0 50px 50px 0 !important;
  border: var(--theme-page-border) !important;
  background-color: var(--theme-page-background) !important;
  padding: 8px !important;
  margin-top: 20px !important ;
  width: 100% !important;
}

.categoriesDropdownArrow {
  padding-top: 5px;
  padding-right: 30px;
}

.categoriesDropdownItems {
  border: none !important;
  background-color: var(--theme-page-background) !important;
  transition: none !important;
  padding: 8px !important;
  font-size: var(--strong-text-font-size);
}

.categoriesDropdownItems:hover {
  padding: 8px !important;
  box-sizing: content-box !important;
  border-radius: 0 50px 50px 0 !important;
  background-color: var(--nav-hover) !important;
  border: none !important;
  cursor: pointer !important;
}

.categoriesDropdownItems:hover > div {
  color: var(--nav-hover-text) !important;
}

.categoriesDropdownItemsSelected {
  padding: 8px !important;
  box-sizing: content-box !important;
  border-radius: 0 50px 50px 0 !important;
  background-color: var(--nav-selected) !important;
  border: none !important;
  cursor: pointer;
  font-size: var(--strong-text-font-size);
  font-weight: bold !important;
}

.categoriesDropdownItemsSelected > div {
  color: var(--nav-selected-text);
}

.categoriesDropdownItemsIcon {
  justify-content: space-around !important;
  padding-left: 5px !important;
}

.categoriesDropdownItemsText {
  font-family: "IBMPlexSans-Light", sans-serif !important;
  color: gray;
}

.hide {
  display: none !important;
}

@media (max-width: 700px) {
  .showMobileNavBar {
    z-index: 1;
    position: absolute !important;
    left: 0;
    top: 7%;
    height: 92%;
    background-color: var(--theme-page-background);
    border-right: var(--theme-page-border);
    border-top: var(--theme-page-border);
  }
}

.signupContainer {
  background-color: var(--main-color) !important;
  min-height: 200px !important;
  border-radius: 0 0 10px 0 !important;
  right: 25px !important;
}

.signupMessage {
  color: white !important;
  padding: 25px !important;
}

.signupMessageA {
  font-family: var(--main-font) !important;
  font-size: 30px !important;
  font-weight: bold !important;
  text-align: center !important;
}

.signupMessageB {
  font-family: var(--main-font) !important;
  font-size: 15px !important;
  font-weight: bold !important;
  text-align: center !important;
}

.signupMessageC {
  font-family: "IBMPlexSans-Light", sans-serif !important;
  font-size: 12px !important;
  text-align: center !important;
}

.buttonContainer {
  position: relative !important;
}

.buttonCenter {
  position: absolute !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

.signupButton {
  font-family: var(--main-font) !important;
  font-size: var(--strong-text-font-size) !important;
  border-radius: 50px !important;
  text-transform: unset !important;
  border: none !important;
  background-color: white !important;
  width: 130px !important;
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.048), 0 11px 11px rgba(0, 0, 0, 0.072),
    0 15px 15px rgba(0, 0, 0, 0.086) !important
;
}

.signupButton:hover {
  background-color: white !important;
}

@media (max-width: 699px) {
  .contentLeftBar {
    height: 5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 170px;
  }
  .categoriesText {
    margin: 0 0 0 10px;
  }
  .categoriesDropdownItems {
    padding: 8px 15px 8px 0 !important;
  }
  .categoriesDropdownItems:hover {
    padding: 8px 15px 8px 0 !important;
  }
  .categoriesDropdownItemsSelected {
    padding: 8px 15px 8px 0 !important;
  }
  .categoriesDropdownItemsIcon {
    padding-left: 0 !important;
  }
}
