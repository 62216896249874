.info {
  width: 100%;
  height: 50px;
  display: flex !important;
  justify-content: flex-start;
  align-items: center;
  color: #909ba7;
  font-weight: normal;
  letter-spacing: 0em;
}

.title {
  margin: 0 0 0 13px;
  font-weight: normal;
}

.content .comment {
  color: #000;
  font-weight: bold;
  line-height: 1.15em;
  letter-spacing: 0.4px;
  height: 10%;
}

.image {
  width: 100%;
  height: 90%;
  text-align: center;
  border-radius: 13px;
  overflow: hidden;
}

.image img {
  width: 100%;
  height: 100%;
}

.options {
  width: 100%;
  height: 100%;
  margin-top: 5px;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
}

.options_right {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-right: 14px;
}

.option_btn {
  width: 2em;
  height: 2em;
  margin-right: 1em;
  display: inline-block;
  border-radius: 6px;
  border: 1px solid #c7cadd;
  cursor: pointer;
}

.myMemes {
  z-index: 1;
  position: absolute !important;
  right: 0;
  top: 7%;
  background-color: #fff;
  height: 92%;
}

.hide {
  display: none !important;
}

.top_bar {
  height: 5%;
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: var(--theme-page-background);
}

.content {
  height: 100%;
  display: flex;
  width: 100%;
  justify-content: space-between;
  background: var(--theme-page-background);
}

.contentCenter {
  height: 100%;
  width: 100%;
  max-width: 75%;
}

.contentRight {
  width: 100%;
  max-width: 25%;
}

.filters {
  height: fit-content; /* 25% */
  width: 100%;
}

.advertisements {
  color: var(--theme-page-text);
  text-align: center;
}

.banner_tag {
  width: 100%;
  margin:10px auto;
  text-align: center;
}
.banner_tag > div{
  margin: auto;
}

@media (max-width: 699px) {
  .top_bar {
    height: 5%;
    max-width: 100%;
  }
}
