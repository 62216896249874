.signUpContainer {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  max-width: 800px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  height: fit-content !important;
  min-height: 150px !important;
  color: white !important;
  display: flex !important;
  justify-content: flex-start !important;
  background-image: url("../../assets/icons/banner.png");
  background-size: cover;
}

.messageContainer {
  width: 70% !important;
  text-align: center !important;
  justify-content: center !important;
  align-items: center !important;
}

.messageA {
  font-family: var(--main-font) !important;
  font-size: 40px !important;
  font-weight: bold !important;
  text-align: left !important;
  padding-left: 40px !important;
  margin-bottom: 5px !important ;
}

.messageB {
  font-family: "IBMPlexSans-Light", sans-serif !important;
  color: var(--main-color) !important;
  font-size: 15px !important;
  text-align: left !important;
  padding-left: 40px !important;
}

.signUpButtonCenter {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 30% !important;
}

.signupButton {
  font-family: var(--main-font) !important;
  font-size: var(--strong-text-font-size) !important;
  border-radius: 50px !important;
  text-transform: unset !important;
  border: none !important;
  background-color: var(--main-color) !important;
  color: white !important;
  width: fit-content !important;
  margin-right: 5px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
}

@media (max-width: 699px) {
  .mobileContainer {
    max-width: 100%;
    height: 10%;
    margin: 0 15px 0 15px;
    box-sizing: border-box;
  }

  .message {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    background-image: url("../../assets/icons/banner.png");
    background-size: cover;
  }

  .message > p {
    font-family: var(--main-font);
    padding: 10px 0 0 20px;
    margin: 0;
  }

  .p1 {
    color: var(--main-color);
    font-size: 20px !important;
    font-weight: bold !important;
  }

  .p2 {
    color: #fff;
    font-size: 14px !important;
  }
}
