.follow {
  display: flex;
  justify-content: center;
  align-items: center;
}

.followBtn {
  font-family: var(--main-font) !important;
  text-transform: unset !important;
  color: var(--main-color) !important;
  border: 1px solid var(--main-color) !important;
  border-radius: 50px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
  line-height: unset !important;
}

.followBtn:hover {
  background-color: var(--followed-button-hover) !important;
  border-color: var(--followed-button-hover) !important;
}

.followBtnSelected {
  font-family: var(--main-font) !important;
  text-transform: unset !important;
  color: var(--main-color) !important;
  border: 1px solid var(--main-color) !important;
  border-radius: 50px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
  background-color: var(--followed-button-selected) !important;
  line-height: unset !important;
}