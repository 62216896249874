.login {
  font-family: var(--main-font) !important;
  font-size: var(--strong-text-font-size) !important;
  border-radius: 50px 0 0 50px !important;
  text-transform: unset !important;
  color: var(--login-button-text) !important;
  border: var(--login-button-border) !important;
  left: 15px;
  width: 150px;
  height: fit-content !important;
  padding-right: 20px !important;
}
.login:hover {
  background-color: var(--nav-hover) !important;
  border: 1px solid var(--nav-hover) !important;
  color: var(--login-button-text-hover) !important;
}

.signin {
  font-family: var(--main-font) !important;
  font-size: var(--strong-text-font-size) !important;
  border-radius: 50px 0 0 50px !important;
  text-transform: unset !important;
  color: white !important;
  background-color: var(--main-color) !important;
  width: 150px;
  height: fit-content !important;
  border: 1px solid var(--main-color) !important;
}

.signin:hover {
  background-color: var(--main-color) !important;
  color: black !important;
}

.follow {
  font-family: var(--main-font) !important;
  font-size: var(--strong-text-font-size) !important;
  border-radius: 50px !important;
  text-transform: unset !important;
  border: none !important;
  width: 150px;
  height: fit-content !important;
  margin-right: 10px !important;
  margin-left: 10px !important;
  color: var(--followed-text) !important;
}

.follow:hover {
  background-color: var(--followed-hover) !important;
}

.followSelected {
  font-family: var(--main-font) !important;
  font-size: var(--strong-text-font-size) !important;
  border-radius: 50px !important;
  text-transform: unset !important;
  border: none !important;
  width: 150px;
  height: fit-content !important;
  margin-right: 10px !important;
  margin-left: 10px !important;
  border: var(--nav-hover) !important;
  background-color: var(--followed-selected) !important;
  color: var(--followed-text) !important;
}

.username {
  font-family: var(--main-font) !important;
  font-size: var(--strong-text-font-size) !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  text-transform: unset !important;
  margin-right: 10px !important;
  margin-left: 10px !important;
  color: var(--username-text) !important;
  border-radius: 50px !important;
  text-decoration: none;
  height: 40.25px !important;
  width: 150px !important;
}

.username .text {
  margin-left: 5px;
}

.username:hover {
  background-color: var(--username-hover) !important;
}

.btnLogoff {
  font-family: var(--main-font) !important;
  font-size: var(--strong-text-font-size) !important;
  border-radius: 50px 0 0 50px !important;
  text-transform: unset !important;
  color: white !important;
  background-color: var(--main-color) !important;
  width: 150px;
  height: fit-content !important;
  border: 1px solid var(--main-color) !important;
}

.btnLogoff:hover {
  background-color: var(--main-color);
  color: black !important;
}

.closeIcon {
  width: 50%;
}

.hide {
  display: none !important;
}

.picture {
  width: 30%;
  border-radius: 70%;
}

@media (max-width: 700px) {
  .loginIcon {
    color: var(--main-color) !important;
  }
}

.iconButton {
  width: 40px !important;
  border-radius: 50% !important;
  font-weight: bold !important;
  text-transform: unset !important;
  padding: unset !important;
  margin-right: 3px !important;
  margin-left: 3px !important;
}

.iconButton:hover {
  background-color: var(--icon-hover) !important;
}

.buttons_container {
  display: flex;
  justify-content: flex-end;
}
.buttons_nav {
  display: flex;
  justify-content: space-between;
}
.buttons_auth {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 699px) {
  .usernameMobile {
    height: fit-content;
    font-family: var(--main-font);
    font-size: var(--strong-text-font-size);
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--username-text);
    text-decoration: none;
    margin: 0 2px 0 2px;
    border-radius: 50px;
  }

  .usernameMobile .text {
    margin-left: 5px;
  }
}
