.menu {
  margin-left: 3rem;
}

.menu .paper {
  border-radius: 0 7px 7px 7px !important;
  width: fit-content !important;
}

.menu .paper > ul {
  padding: 0 !important;
}

.menu .paper > ul > li {
  font-family: var(--main-font) !important;
  background-color: #fff;
  padding: 0 !important;
}

.menuDark {
  margin-left: 3rem;
}

.menuDark .paper {
  border-top: 1px solid #808080 !important;
  border-left: 1px solid #808080 !important;
  border-right: 1px solid #808080 !important;
  border-radius: 0 7px 7px 7px !important;
  width: fit-content !important;
  background-color: #000;
}

.menuDark .paper > ul {
  padding: 0 !important;
}

.menuDark .paper > ul > li {
  font-family: var(--main-font) !important;
  background-color: #000;
  padding: 0 !important;
  border-bottom: 1px solid #808080 !important;
}

.menuBtn {
  font-family: var(--main-font) !important;
  text-transform: unset !important;
  width: 100% !important;
  height: 100% !important;
  padding-left: 15px !important;
  padding-right: 35px !important;
  border-radius: 0px !important;
}

.menuBtn:hover {
  background-color: var(--main-hover) !important;
}

.menuBtnDark {
  font-family: var(--main-font) !important;
  text-transform: unset !important;
  width: 100% !important;
  height: 100% !important;
  padding-left: 15px !important;
  padding-right: 35px !important;
  border-radius: 0px !important;
  color: #fff !important;
  margin: 0 !important;
}

.menuBtnDark:hover {
  background-color: #393939 !important;
}
