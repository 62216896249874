.user_image {
  width: 100%;
  height: 100%;
}

.user_image img {
  width: 100%;
}

.option_btn {
  width: 100%;
  height: 3em;
  display: flex;
  align-items: center;
  margin-top: 3em;
  border-radius: 4px;
  border: 1px solid #c7cadd;
  cursor: pointer;
}

.option_btn .likes {
  width: 15%;
  height: 80%;
  border-radius: 25%;
  margin: 0px 5px 0px 5px;
  background: url("../../assets/icons/likes.svg") no-repeat center;
}

.option_btn .comments {
  width: 15%;
  height: 80%;
  border-radius: 25%;
  margin: 0px 5px 0px 5px;
  background: url("../../assets/icons/comments.svg") no-repeat center;
}

.option_btn .views {
  width: 15%;
  height: 80%;
  border-radius: 25%;
  margin: 0px 5px 0px 5px;
  background: url("../../assets/icons/views.svg") no-repeat center;
}

.option_btn .subscribers {
  width: 15%;
  height: 80%;
  border-radius: 25%;
  margin: 0px 5px 0px 5px;
  background: url("../../assets/icons/subscribers.svg") no-repeat center;
}

.option_btn .views {
  width: 15%;
  height: 80%;
  border-radius: 25%;
  margin: 0px 5px 0px 5px;
  background: url("../../assets/icons/views.svg") no-repeat center;
}
