 .searchBar_container {
  max-width: 60%;
  width: 60%;
  min-width: 20%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 10px 0 0 0;
}

#searchBar {
  width: 100%;
  padding-left: 50px !important;
}
.search {
  background: var(--theme-page-background);
  border-radius: 50px;
  width: 60%;
  padding: 5px;
  padding-left: 15px !important;
  border: var(--theme-page-border) !important;
  font-family: "IBMPlexSans-Light", sans-serif !important;
  font-size: var(--strong-text-font-size) !important;
  color: var(--searchbar-text) !important;
}

@media (max-width: 699px) {
  .mobileContainer {
    display: flex;
    max-width: 100%;
    height: 10%;
    margin: 0 15px 0 15px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
  }
  .mobileForm {
    width: 100%;
    margin: 0;
    height: fit-content;
  }

  .mobileSearch_old {
    width: 100%;
    background: var(--theme-page-background); 
    border-radius: 50px;
    padding: 3px;
    padding-left: 15px !important;
    border: var(--theme-page-border) !important;
    font-family: "IBMPlexSans-Light", sans-serif !important;
    font-size: var(--strong-text-font-size) !important;
    color: var(--searchbar-text) !important;
  }
} 