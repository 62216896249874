@font-face {
  font-family: "IBM Plex Sans";
  src: local("IBM Plex Sans"),
    url("../fonts/IBMPlexSans/IBMPlexSans-Bold.ttf") format("opentype");
}

:root {
  /*Main*/
  --strong-text-font-size: 15px;
  --main-color: #ff3651;
  --main-font: "IBM Plex Sans", sans-serif;
  --main-hover: #fff2f3;
  --main-selected: #ffe4e8;
  --main-border: 1px solid #dad5d5;
  --nav-hover: #ffe4e8;
}

#light {
  /*Main*/
  --theme-page-background: #fff;
  --theme-page-text: #000;
  --theme-page-border: 1px solid #dad5d5;

  /* Nav*/
  --nav-hover: #fff2f3;
  --nav-hover-text: #000;
  --nav-selected: #ffe4e8;
  --nav-selected-text: var(--main-color);

  /*SearchBar*/
  --searchbar-text: #808080;

  /*UserOptions*/
  --login-button-border: 1px solid #f0d7df;
  --login-button-text: var(--main-color);
  --login-button-text-hover: var(--main-color);
  --username-hover: #fff2f3;
  --username-text: #000;
  --followed-hover: #fff2f3;
  --followed-text: #000;
  --followed-selected: #ffe4e8;
  --icon-hover: #fff2f3;

  /*Filters*/
  --filter-selected-text: #fff;
  --filter-hover: #efefef;

  /*Meme*/
  --meme-background: #fff;
  --meme-border: 1px solid #dad5d5;

  /*MemeUserInfo*/
  --followed-button-hover: #fff2f3;
  --followed-button-selected: #ffe4e8;
  --followed-button-border: 1px solid #ffe4e8;
  --menu-background: #fff;
  --menu-button-hover: #fff2f3;
  --menu-button-selected: #ffe4e8;

  /*MemeContent*/
  --meme-content-description: #000;

  /*MemeOptions*/
  --meme-comment-counter: #000;
  --meme-option-border: 1px solid #ffe4e8;
  --meme-option-hover: #fff2f3;
  --meme-option-selected: #ffe4e8;
  --meme-comment-count-border: 1px solid #dad5d5;

  /*MemeComments*/
  --meme-comment-border: 1px solid #dad5d5;

  /*Comment*/
  --comment-text: var(--theme-page-text);

  /*Commenter*/
  --commenter-text: var(--theme-page-text);
  --commenter-theme-message: #ffe4e8;
}

#dark {
  /*Main*/
  --theme-page-background: #141414;
  --theme-page-text: #fff;
  --theme-page-border: 1px solid #808080;

  /* Nav*/
  --nav-hover: #202020;
  --nav-hover-text: var(--theme-page-text);
  --nav-selected: #393939;
  --nav-selected-text: var(--theme-page-text);

  /*SearchBar*/
  --searchbar-text: var(--theme-page-text);

  /*UserOptions*/
  --login-button-border: 1px solid var(--main-color);
  --login-button-text: var(--theme-page-text);
  --login-button-text-hover: var(--main-color);
  --username-hover: #202020;
  --username-text: var(--theme-page-text);
  --followed-hover: #202020;
  --followed-text: var(--theme-page-text);
  --followed-selected: #393939;
  --icon-hover: #202020;

  /*Filters*/
  --filter-selected-text: #141414;
  --filter-hover: #202020;

  /*Meme*/
  --meme-background: #2d2d2d;
  --meme-border: 1px solid #2d2d2d;

  /*MemeUserInfo*/
  --followed-button-hover: #393939;
  --followed-button-selected: #141414;
  --followed-button-border: 1px solid #141414;
  --menu-background: #000;
  --menu-button-hover: #2d2d2d;
  --menu-button-selected: var(--main-color);

  /*MemeContent*/
  --meme-content-description: var(--theme-page-text);

  /*MemeOptions*/
  --meme-comment-counter: var(--theme-page-text);
  --meme-option-border: 1px solid var(--main-color);
  --meme-option-hover: #393939;
  --meme-option-selected: #141414;
  --meme-comment-count-border: 1px solid #808080;

  /*MemeComments*/
  --meme-comment-border: 1px solid #808080;

  /*Comment*/
  --comment-text: var(--theme-page-text);

  /*Commenter*/
  --commenter-text: #808080;
  --commenter-theme-message: #2d2d2d;
}
